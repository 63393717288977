import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { decrypt, encrypt } from "utility/crypto.helper";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const onSigninCallback = () => {
  window.location.replace("/");
};

const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: "https://ontll-faq.mbtt-chatbot.i.mercedes-benz.com/redirect",
  scope: "openid profile email offline_access",
  userStore: new WebStorageStateStore({
    store: {
      getItem: (key) => {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
          return decrypt(encryptedData);
        }
        return null;
      },
      setItem: (key, value) => {
        const encryptedValue = encrypt(value);
        localStorage.setItem(key, encryptedValue);
      },
      removeItem: localStorage.removeItem,
    },
  }),
  response_type: "code",
  grant_type: "authorization_code",
  automaticSilentRenew: false,
  loadUserInfo: true,
  acr_values: "daimler:idp:gas:standard",
  response_mode: "query",
  onSigninCallback,
  // authority: "https://demo.duendesoftware.com/",
  // client_id: "interactive.public.short",
  // redirect_uri: `${window.location.origin}/redirect`,
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
