import { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { v4 as uuidv4 } from "uuid";
import { readMsgCache, setMsgCache } from "utility/msgCache.helper";
import sanitizeHtml from "sanitize-html";
import OppositeMsgBubble from "components/OppositeMsgBubble";
import OwnMsgBubble from "components/OwnMsgBubble";
import MsgInput from "components/MsgInput";
import axiosChat from "utility/axiosConfig";
import sendingGif from "assets/icons/tpying.gif";
import generateContextMsg from "utility/msgContext.helper";

const Chatbot = () => {
  const auth = useAuth();
  const chatbotContainerRef = useRef();
  const dialogContainerRef = useRef(null);
  const [msgValue, setMsgValue] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Welcome to OnTLL Chatbot. Ask me anything about OnTLL. / Willkommen beim OnTLL Chatbot. Fragen Sie zu OnTLL.",
      id: uuidv4(),
      type: "other-welcome",
    },
  ]);

  useEffect(() => {
    const msgCache = readMsgCache();
    if (msgCache && Array.isArray(msgCache) && msgCache.length) {
      setMessages(msgCache);
      setTimeout(() => {
        dialogContainerRef?.current?.lastElementChild?.scrollIntoView({
          behavior: "smooth",
        });
      }, 250);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dialogContainerRef?.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);

    if (messages.length > 1) {
      setMsgCache(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (!chatbotContainerRef.current) return undefined;
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        dialogContainerRef?.current?.lastElementChild?.scrollIntoView({
          behavior: "smooth",
        });
      }, 250);
    });
    resizeObserver.observe(chatbotContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleSendMsg = async () => {
    if (msgValue && msgValue.trim()?.length > 0 && !isSending) {
      const currentMsg = sanitizeHtml(msgValue);
      setIsSending(true);
      setMessages((prev) => [
        ...prev,
        {
          text: currentMsg,
          id: uuidv4(),
          type: "own",
        },
      ]);
      setMsgValue("");

      const textArea = document.getElementById("chatbot-footer-msg-input");
      if (textArea) {
        setTimeout(() => {
          textArea.style.height = "36px";
          textArea.style.height = `${textArea.scrollHeight}px`;
        }, 50);
      }

      let contextMsg = null;
      if (
        process.env.REACT_APP_USE_CHAT_CONTEXT === "TRUE" &&
        Number(process.env.REACT_APP_CHAT_CONTEXT_COUNT)
      ) {
        contextMsg = generateContextMsg(messages, currentMsg);
      }

      try {
        const { data } = await axiosChat.post("/input", {
          message: contextMsg || currentMsg,
        });

        if (data?.message) {
          setMessages((prev) => [
            ...prev,
            {
              text: sanitizeHtml(data.message),
              id: uuidv4(),
              type: "other",
            },
          ]);
        }
      } catch (err) {
        console.log(err);
        if (err?.response && err.response.status === 401) {
          auth.signinRedirect();
          return;
        }

        setMessages((prev) => [
          ...prev,
          {
            text: "Sorry, an error occurred.",
            id: uuidv4(),
            type: "other-error",
          },
        ]);
      } finally {
        setIsSending(false);
      }
    }
  };

  return (
    <div className="chatbot-container" ref={chatbotContainerRef}>
      <wb-card>
        <div className="chatbot-header">
          <div className="chatbot-header-title">
            <wb-heading size="m">OnTLL Chatbot</wb-heading>
          </div>
        </div>
        <div className="chatbot-dialog-container" ref={dialogContainerRef}>
          {messages.map((message, index) => {
            const nextDifferent = message.type !== messages[index + 1]?.type;
            if (message.type === "own") {
              return (
                <OwnMsgBubble
                  text={message.text}
                  key={message.id}
                  nextDifferent={nextDifferent}
                />
              );
            }

            return (
              <OppositeMsgBubble
                text={message.text}
                key={message.id}
                nextDifferent={nextDifferent}
              />
            );
          })}
        </div>
        <div className="chatbot-footer">
          {isSending ? (
            <img
              src={sendingGif}
              alt="message sending dots"
              className="msg-sending-dots"
            />
          ) : null}
          <MsgInput
            msgValue={msgValue}
            setMsgValue={setMsgValue}
            handleSendMsg={handleSendMsg}
          />
        </div>
      </wb-card>
    </div>
  );
};

export default Chatbot;
