import axios from "axios";
import { decrypt } from "./crypto.helper";

const myAxios = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://ontll-faq.mbtt-chatbot.api.mercedes-benz.com",
});

myAxios.interceptors.request.use((config) => {
  // oidc.user:https://demo.duendesoftware.com/:interactive.public.short
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTHORITY_URL}:${process.env.REACT_APP_CLIENT_ID}`
  );

  if (oidcStorage) {
    const decryptedStorage = decrypt(oidcStorage);
    const parsedStorage = JSON.parse(decryptedStorage);

    if (config.headers && parsedStorage?.access_token) {
      config.headers.Authorization = `Bearer ${parsedStorage.access_token}`;
    }
  }

  return config;
});

myAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default myAxios;
