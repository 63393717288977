export const setMsgCache = (msgData) => {
  const timestamp = Date.now();

  try {
    localStorage.setItem("msgCache", JSON.stringify({ msgData, timestamp }));
  } catch (err) {
    console.log(err);
  }
};

export const readMsgCache = () => {
  const cachedDataString = localStorage.getItem("msgCache");

  if (cachedDataString) {
    const { msgData, timestamp } = JSON.parse(cachedDataString);

    const now = Date.now();
    if (now - timestamp <= 6 * 60 * 60 * 1000) {
      return msgData;
    }

    localStorage.removeItem("msgCache");
    return null;
  }

  return null;
};
