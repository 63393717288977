import { ReactComponent as SendMsgIcon } from "assets/icons/send-message.svg";

const MsgInput = ({ msgValue, setMsgValue, handleSendMsg }) => {
  const handleKeyUp = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSendMsg();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    setMsgValue(e.target.value);
    e.target.style.height = "36px";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <>
      <textarea
        value={msgValue}
        onChange={handleInputChange}
        placeholder="Message here / Nachricht hier"
        className="chatbot-footer-msg-input"
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        id="chatbot-footer-msg-input"
      />
      <wb-round-button onClick={handleSendMsg} variant="primary" size="s">
        <SendMsgIcon className="chatbot-footer-send-msg" />
      </wb-round-button>
    </>
  );
};

export default MsgInput;
