import * as CryptoJS from "crypto-js";

const AES = {
  Key: "4360a87b12ad1659f2418209ba6dcaf5f16d221d021833d0337e38b8f7802240",
  IV: "d07d65d0f0996873b8ddae181fba61f2",
  KeySize: 32,
};

const KEY = CryptoJS.enc.Utf8.parse(AES.Key);
const KEY_STR = KEY.toString();

export const encrypt = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(data, KEY_STR).toString();
  return ciphertext;
};

export const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, KEY_STR);
  const originalData = bytes.toString(CryptoJS.enc.Utf8);
  return originalData;
};
