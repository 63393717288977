const OppositeMsgBubble = ({ text, nextDifferent }) => (
  <div className={`opposite-msg-container ${nextDifferent ? "last" : ""}`}>
    <wb-icon name="bds/mb-star/24" />
    <div className="opposite-msg-container-text">
      <wb-text>{text}</wb-text>
    </div>
  </div>
);

export default OppositeMsgBubble;
