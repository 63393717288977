import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { hasAuthParams, useAuth } from "react-oidc-context";
import LoadingContainer from "components/LoadingContainer";
import AuthFailed from "components/AuthFailed";

const AuthController = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  // console.log("AuthController auth", auth);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    console.log("");
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth, auth.events, auth.signinSilent]);

  if (auth.isAuthenticated) {
    return <Outlet />;
  }

  if (auth.isLoading) {
    return <LoadingContainer />;
  }

  if (!auth.isAuthenticated) {
    return <AuthFailed />;
  }

  return <Outlet />;
};

export default AuthController;
