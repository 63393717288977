import { Route, Routes } from "react-router-dom";
import "styles/global.scss";
import Chatbot from "pages/Chatbot";
import AuthController from "pages/AuthController";
import RedirectLayout from "pages/RedirectLayout";

console.log("process.env", process.env);

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthController />}>
        <Route path="/" element={<Chatbot />} />
      </Route>
      <Route path="/redirect" element={<RedirectLayout />} />
    </Routes>
  );
}

export default App;
